export const environment = {
  production: true,
  backendUrl: 'https://test-back.mastercheck.upowa.org/umaestro/ws',
  kpiAgentUrl: 'https://test-back.udash.upowa.org/udash-hub',
  APP_VERSION: 'TEST',
  FIREBASE_CONFIG: {
    apiKey: 'AIzaSyBc59i5h3-b_XHNMV9qoLVdcyLy7W2JzFU',
    authDomain: 'wpa-app-7b545.firebaseapp.com',
    projectId: 'wpa-app-7b545',
    storageBucket: 'wpa-app-7b545.appspot.com',
    messagingSenderId: '377234956360',
    appId: '1:377234956360:web:d87e4e8f9530ef095f387d',
    measurementId: 'G-PJ6CHJ16LT'
  }
};
